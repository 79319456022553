import "./App.css";
import { Col, Container, Row } from "react-bootstrap";
import { FaTwitter, FaTelegram } from "react-icons/fa";

function App() {
  return (
    <>
      <div>
        <Container className="d-flex align-items-center justify-content-center h-100">
          <div className="mintbox pb-5">
            <Container className="d-flex justify-content-center">
              <div className="mint">
                <div className="text-white font-weight-bold">
                  <h1 className="mih1 text-center text-uppercase mb-3">
                    Don't be SAD
                  </h1>
                  <p className="subtitle p-0 m-0 text-center  text-uppercase">
                    Life is kind of like a party
                  </p>

                  <div className="text-center mt-2">
                    <a
                      className="mx-2"
                      href="https://twitter.com/elonmusk/status/1734010714510049396"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{
                          border: "1px solid #4f4d4d",
                          cursor: "pointer",
                        }}
                        className="img-fluid xt"
                        src={require("./assets/x.png").default}
                        alt=""
                      />
                    </a>
                  </div>

                  <div className="text-center mt-2">
                    <a
                      className="mx-2 text-white"
                      href="https://twitter.com/dontbesadcoin"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>

                    <a
                      className="mx-2 text-white"
                      href="https://t.me/dontbesadethcoin"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Telegram
                    </a>

                    <a
                      className="mx-2 text-white"
                      href="https://www.dextools.io/app/en/ether/pair-explorer/0x7d3595f247ead041fe1c29b7ad06e1ec9bb4f589"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Dextools
                    </a>

                    <a
                      className="mx-2 text-white"
                      href="https://etherscan.io/token/0x5fe9ffd8798fdc605740932976969afa6a492b2a"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contract
                    </a>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </Container>
      </div>
    </>
  );
}

export default App;
